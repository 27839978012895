import React from 'react'
import { TextField } from '@material-ui/core'
import { LogotypeContext } from '../../store/contexts/logotype'
import { LOGOTYPE_STORE } from '../../store/types/logotype'
const LogotypeInput = () => {
  const { dispatch }: any = React.useContext(LogotypeContext)
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: LOGOTYPE_STORE,
      payload: {
        field: 'name',
        value: e.target.value
      }
    })
  }
  return (
    <div>
      {/* <Input
        style={{
          width:100%
        }}
      placeholder="Write your brand here!"
      onChange={handleChange}
      /> */}
      <TextField
        style={{
          width:"100%"
        }}
        id='field_brand'
          label="Write your brand here!"
        variant="outlined"
         onChange={handleChange}
        />
      </div>
  )
}

export default LogotypeInput
