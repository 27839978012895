import React from 'react'
import { Box, Drawer, Grid, Toolbar,Button,Icon } from '@material-ui/core'
import { SidebarContext } from '../../store/contexts/sidebar'
import { useStyles } from './useStyles'
import * as Sections from './sections'
import { SIDEBAR_SHOW } from '../../store/types/sidebar'

import './style.css';
const sections = [
  // { id: 'Color', text: 'Colors' },
  { id: 'Icon', text: 'Icons' }
]
const Sidebar = () => {
  const [active] = React.useState('Icon')
  // const [active, setActive] = React.useState('Icon')
  const { state: sidebar }: any = React.useContext(SidebarContext)
  const classes = useStyles({ sidebar })
const { dispatch }: any = React.useContext(SidebarContext)
  const ActiveSection = () => {
    const AllSections: Record<string, any> = Sections
    const DynamicSection = AllSections[active]
    return <DynamicSection />
  }
  return (
    <Drawer
      className={classes.drawer}
      id="sidebar_r"
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
    >

      <Toolbar>
        <Grid container>
          {sections.map(section => (
            <Grid key={section.id} item sm={12}>
                     <div className={classes.drawerHeader}>
          <Button  id="sidebar_btn" onClick={() => dispatch({ type: SIDEBAR_SHOW })}
                >
                  Home
                  <Icon>navigate_next</Icon>
                  
          </Button>
        </div>
              {/* <Button
                fullWidth
                size="small"
                onClick={() => setActive(section.id)}
                disabled={section.id === active}
                className={section.id === active ? classes.active : ''}
              >
                {section.text}
              </Button> */}
            </Grid>
          ))}
        </Grid>
      </Toolbar>
      <Box className={classes.box} style={{padding:0}}>
        <Box style={{ padding: 0 }}>
          {ActiveSection}
        </Box>
        </Box>
    </Drawer>
  )
}

export default Sidebar
