import React from 'react'
import { Button } from '@material-ui/core'
import { SidebarContext } from '../../store/contexts/sidebar'
import { SIDEBAR_SHOW } from '../../store/types/sidebar'
import Icon from '@material-ui/core/Icon';
const SidebarButton = () => {
  const { dispatch }: any = React.useContext(SidebarContext)
  return (

       <Button
        variant="contained"
        color="primary"
        size="small"
        
        startIcon={<Icon>tune</Icon>}
          onClick={() => dispatch({ type: SIDEBAR_SHOW })}
      >
        Icons
      </Button>
  )
}

export default SidebarButton
