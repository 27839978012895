import React from 'react'
import { IconButton ,Typography } from '@material-ui/core'
import { LogotypeContext } from '../../store/contexts/logotype'
import { LOGOTYPE_STORE } from '../../store/types/logotype'
import { useStyles } from './useStyles'
import './style.css'
const items = [
  { id: 'LOG', text: '/icons/t.svg' },
  { id: 'LIH', text: '/icons/v.svg' },
  { id: 'LIV', text: '/icons/h.svg' },
  { id: 'ISO', text: '/icons/l.svg' }
]

const Navigation = () => {
  const classes = useStyles()
  const { state: logotype, dispatch }: any = React.useContext(LogotypeContext)
  const handleClick = (id: string) => {
    dispatch({
      type: LOGOTYPE_STORE,
      payload: {
        field: 'layout',
        value: id
      }
    })
  }
  return (
    <React.Fragment>
      <Typography variant="caption" style={{
        width: "auto",
        display: "inline-block",
        margin:0
      }} className={classes.layoutText}>
        Layouts : 
      </Typography>
      {items.map((item, key) => (
        <React.Fragment key={item.id}>
          <IconButton 
            onClick={() => handleClick(item.id)}
            disabled={item.id === logotype.layout}
            className={item.id === logotype.layout ? classes.active : ''}
            size="small"
            style={{
              padding: "0px",
              margin:5,
              display: "inline-block",
              width:"auto",
              borderRadius: "200%",
              verticalAlign:"middle"
            }}
          >
            <img alt="logos" src={process.env.PUBLIC_URL + item.text } className='logos'/> 
            {/* {item.text} */}
          </IconButton>
          {/* {items.length !== (key + 1) && (
            <Divider 
              orientation="vertical" 
              flexItem 
              className={classes.divider}
            />
          )} */}
        </React.Fragment>
      ))}
    </React.Fragment>  
  )
}

export default Navigation
