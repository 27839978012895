import React from 'react'
import { Box, Icon, Card, Typography } from '@material-ui/core'
import { LogotypeContext } from '../../store/contexts/logotype'
import ButtonList from './ButtonList'
import { useStyles } from './useStyles'

// import axios from "axios"

type Props = {
  negative?: boolean
}

// var NounProject = require('the-noun-project'),
// nounProject = new NounProject({
//     key: '85da2f35afc74c1696d615c3034c104b',
//     secret: 'fa4867e7249c4afd8e0e323ebde028d7'
// });
// nounProject.getIconsByTerm('goat', {limit: 5}, function (err:any, data:any) {
//     if (!err) {
//         console.log(data.icons);
//     }
// });
  
const Logotype:React.FC<Props> = ({ negative = false }) => {
  const { state: logotype }: any = React.useContext(LogotypeContext)
  const backgroundColor = negative ? logotype.background_color : 'transparent'
  const color =  logotype.color
  const htmlDivElementRef = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const classes = useStyles({ layout: logotype.layout })

  return (
    <Box>
      <Card
        variant="outlined" 
        elevation={0} 
        className={classes.card}
        style={{ backgroundColor, color }}
      >
        <div className={classes.box} ref={htmlDivElementRef} style={{textAlign:"center"}}>
          {logotype.layout !== 'LOG' && (
            <Icon className={classes.isotype}>
              {logotype.isotype}
            </Icon>
          )}
          {logotype.layout !== 'ISO' && (
            <Typography 
              variant="h2" 
              component="div" 
              style={{ 
                fontFamily: logotype.fontFamily,
                fontWeight: logotype.fontWeight,
                fontStyle: logotype.fontStyle,
                color: logotype.color_text_1,
              }}>
              {logotype.name}
            </Typography>
          )}
        </div>
      </Card>

      <Box component="footer" mt={3}>
        <ButtonList 
          htmlDivElementRef={htmlDivElementRef}
          backgroundColor={backgroundColor} 
        />
      </Box>
    </Box>
  )
}

export default Logotype
