import React from 'react'
import { AppBar,Grid } from '@material-ui/core'
import { SidebarContext } from '../../store/contexts/sidebar'
import Logotype from './Logotype'
import Navigation from '../Navigation'
// import LogotypeReset from './LogotypeReset'

import LogotypeStyle from './LogotypeStyle'
import LogotypeWeight from './LogotypeWeight'
import LogotypeColor from './LogotypeColor'
import SidebarButton from './SidebarButton'
import LogoFontPicker from './LogoFontPicker'
import { useStyles } from './useStyles'

const Navbar = () => {
  const { state: sidebar }: any = React.useContext(SidebarContext)
  const classes = useStyles({ sidebar })
  // const CustomDivider = () => (
  //   <Divider orientation="vertical" flexItem className={classes.dividerSmall} />
  // ) 
  return (
    <AppBar
      position="static"
      color="inherit"
      elevation={0}
      className={classes.root}
    >
      <div style={{padding:10}}>
        <Grid container alignItems='center' alignContent='center'>
          <Grid item xs={12} sm={2}>
            <Grid container  alignItems='center' alignContent='center'>
              <Grid item xs={12} sm={12}>
                <Logotype />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container alignItems='center' alignContent='center'>
                <Grid item xs={7} sm={7}>
                <LogotypeColor />
              </Grid>
              <Grid item xs={1} sm={1}>
                <LogotypeWeight />
              </Grid>
              <Grid item xs={1} sm={1}>
                <LogotypeStyle />
              </Grid>
              <Grid item xs={3} sm={3} style={{
                textAlign:"center"
              }}>
                <LogoFontPicker />
              </Grid>
            </Grid>
          </Grid>
           <Grid item xs={12} sm={4}>
            <Grid container alignItems='center' alignContent='center' zeroMinWidth>
              <Grid item xs={9} sm={9}>
                <Navigation />
              </Grid>
              <Grid item xs={3} sm={'auto'} style={{
                textAlign:"center"
              }}>
                   <SidebarButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </AppBar>
  )
};

export default Navbar
