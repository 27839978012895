import React from 'react'
import { LogotypeContext } from '../../store/contexts/logotype'
import { LOGOTYPE_STORE } from '../../store/types/logotype'
import FontPicker from "font-picker-react";
const LogoFontPicker = () => {
 const { state: logotype, dispatch }: any = React.useContext(LogotypeContext)
  return (
    <FontPicker
        apiKey="AIzaSyB7Q8RqDGSbVDBstClQ5CdXRKAYwxmxMxM"
        activeFontFamily={logotype.fontFamily}
        limit={100}
        onChange={(nextFont) =>
          dispatch({
            type: LOGOTYPE_STORE,
            payload: {
              field: 'fontFamily',
              value: nextFont.family
            }
          })
        } />
  )
}

export default LogoFontPicker
