import React from 'react'
import { Box, Button, Grid, Icon as MatIcon, Input,InputAdornment  } from '@material-ui/core'
import { LogotypeContext } from '../../../../store/contexts/logotype'
import { LOGOTYPE_STORE } from '../../../../store/types/logotype'
import { icons } from '../../../../utlis/icons'

const Icon = () => {
  const [filteredList, setFilteredList] = React.useState(icons);
  const iconPerRow = 20;
  const [next, setNext] = React.useState(iconPerRow);
function filterBySearch (event:any) {
    const query = event.target.value;
    var updatedList = [...icons];
    updatedList = updatedList.filter((item) => {
      return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredList(updatedList);
  }
  const { state: logotype, dispatch }: any = React.useContext(LogotypeContext)
  const handleClick = (icon: string) => {
    dispatch({
      type: LOGOTYPE_STORE,
      payload: {
        field: 'isotype',
        value: icon
      }
    })
  }
  
  const handleMoreImage = () => {
    setNext(next + iconPerRow);
  };
  return (
    <Box style={{
      padding:5
    }}>
       
      <Input fullWidth onChange={filterBySearch} placeholder='What`s your logo for?'
         endAdornment={
            <InputAdornment position="end">
              <MatIcon>search</MatIcon>
            </InputAdornment>
          }
        style={{
          padding:5,
          marginBottom:15
        }}
      ></Input>
      <Grid container  
   direction="row"
   justifyContent="space-between"
   alignItems="flex-start">
        {filteredList?.slice(0,next)?.map(name => 
          <Grid key={name} item sm={4}>
            <Button
              fullWidth
              variant={name === logotype.isotype ? 'contained' : 'outlined'}
              disableElevation
              style={{ 
                paddingTop: 20, 
                paddingBottom: 20,
              }}
              color={name === logotype.isotype ? 'primary' : 'default'}
              onClick={() => handleClick(name)}
            >
              <MatIcon fontSize="large">{name}</MatIcon>
            </Button>
          </Grid>
        )}
      </Grid>
        {next < filteredList?.length && (
          <Button
            className="mt-4 full-width p3"
          onClick={handleMoreImage}
          style={{
            width: '100%',
            padding: '8px',
            marginTop:'5px'
          }}
          >
            Load more
          </Button>
        )}
    </Box>
  )
}

export default Icon
