import React from 'react'
import { Box, Icon, Typography } from '@material-ui/core'
import './style.css'
const Logotype = () => {
  return (
    <React.Fragment >
      <Box display="flex" mr={2} flexGrow={1} id="LogoSectionTop" alignItems="flex-start">
        <Icon style={{ fontSize: 28 }}>favorite</Icon>
            <Typography 
        variant="h6" 
        noWrap
        style={{ fontFamily: 'Montserrat' }}
      >
        <strong>IWINGS</strong>
      </Typography>
      </Box>        
  
    </React.Fragment>
  )
}

export default Logotype
