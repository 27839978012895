import React from 'react'
import { Grid } from '@material-ui/core'
import Navbar from '../../components/Navbar'
import Sidebar from '../../components/Sidebar'
import LogoSection from './LogoSection'
import Footer from '../../components/Footer'
import { useStyles } from './useStyles'


const Home = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
       <Grid container  direction="row"
  justifyContent="center"
  alignItems="center" >
        <Grid item xs={12}>
             <Navbar />
        </Grid>
   
      {/* <main className={classes.content}> */}
        {/* <Toolbar /> */}
        <Grid item xs={12} style={{
            padding:15
          }}>
           <LogoSection />
        </Grid>
      
        {/* <Toolbar /> */}
        {/* </main> */}
         <Grid item xs={12}>
          <Footer />
        </Grid>
                    <Grid item xs={12}>
       <div style={{ "position": "absolute"}}>
        <Sidebar />  
      </div>
        </Grid>
        </Grid>
 
    </div>
  )
}

export default Home