import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {Grid,Typography } from '@material-ui/core'
import { ColorPicker, Color } from 'material-ui-color'
import { LogotypeContext } from '../../store/contexts/logotype'
import { LOGOTYPE_STORE } from '../../store/types/logotype'
// import FontPicker from "font-picker-react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiButtonBase-root': {
        boxShadow: 'none !important'
      }
    },
    formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    },
      layoutText: {
      color: theme.palette.grey[500],
      textTransform: 'uppercase',
      marginRight: theme.spacing(3)
    },
  })
)

const LogotypeColor = () => {
  const classes = useStyles()
  const { state: logotype, dispatch }: any = React.useContext(LogotypeContext)
  // const [theFont, setFont] = React.useState('');
  const handleChange = (color: Color) => {
    dispatch({
      type: LOGOTYPE_STORE,
      payload: {
        field: 'color',
        value: `#${color.hex}`
      }
    })
  }
  const handleChange_test_1 = (color: Color) => {
    dispatch({
      type: LOGOTYPE_STORE,
      payload: {
        field: 'color_text_1',
        value: `#${color.hex}`
      }
    })
  }
  const handleChange_background_color = (color: Color) => {
    dispatch({
      type: LOGOTYPE_STORE,
      payload: {
        field: 'background_color',
        value: `#${color.hex}`
      }
    })
  }
    

  // const handleChange_fonts = (event: any) => {
  //   console.log(event.target.value);
  //   dispatch({
  //     type: LOGOTYPE_STORE,
  //     payload: {
  //       field: 'fontFamily',
  //       value: event.target.value
  //     }
  //   })
  // }
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        alignContent='center'
        alignItems="center"
        
      >
        <Typography variant="caption" style={{
          width: "auto",
          display: "inline-block",
          margin: 0
        }} className={classes.layoutText}>
          Icon :
        </Typography>
        <ColorPicker
          value={logotype.color}
          hideTextfield
          disableAlpha
        
          onChange={handleChange}
        />
        <Typography variant="caption" style={{
          width: "auto",
          display: "inline-block",
          margin: 0
        }} className={classes.layoutText}>
          Font :
        </Typography>
        <ColorPicker
          value={logotype.color_text_1}
          hideTextfield
          disableAlpha
        
          onChange={handleChange_test_1}
        />
        <Typography variant="caption" style={{
          width: "auto",
          display: "inline-block",
          margin: 0
        }} className={classes.layoutText}>
          BG :
        </Typography>
        <ColorPicker
          value={logotype.background_color}
          hideTextfield
          disableAlpha
        
          onChange={handleChange_background_color}
        />
    
      </Grid>
    </div>
  )
};

export default LogotypeColor
