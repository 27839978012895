import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
// import Heading from '../../components/Heading'
import Logotype from '../../components/Logotype'
import LogotypeInput from '../../components/Navbar/LogotypeInput'
const LogoSection = () => {
  return (
    <Box >
      <div id="input_form_top">
      {/* <Typography > 
        What is your brand name?
      </Typography> */}
      <Box mb={1}>
        <Typography variant="body2">
          <LogotypeInput/>
        </Typography>
      </Box>
</div>
      <Grid container style={{
        justifyContent: "center"
      }} >
        <Grid item md={12} lg={6} style={{
          padding: 5,
        textAlign:"center"}}>
          <Logotype />
        </Grid>
        <Grid item md={12} lg={6} style={{padding:5,  textAlign:"center"}}>
          <Logotype negative />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LogoSection
